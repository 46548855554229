import styled from '@emotion/styled';
import {graphql, Link} from 'gatsby';
import React from 'react';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {rhythm} from '../utils/typography';

interface Props {
  data: {
    allMarkdownRemark: any;
  };
}

const websiteIndex = ({data}: Props) => {
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout isBigHeader={true}>
      <SEO title="Home" />
      <Bio />
      {posts.map(({node}: {node: any}) => {
        const title = node.frontmatter.title || node.fields.slug;
        return (
          <div key={node.fields.slug}>
            <H3>
              <Link style={{boxShadow: `none`}} to={node.fields.slug}>
                {title}
              </Link>
            </H3>
            <small>{node.frontmatter.date}</small>
            <p
              dangerouslySetInnerHTML={{
                __html: node.frontmatter.description || node.excerpt,
              }}
            />
          </div>
        );
      })}
    </Layout>
  );
};

export default websiteIndex;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {frontmatter: {published: {ne: false}}}
      sort: {fields: [frontmatter___date], order: DESC}
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;

const H3 = styled.h3`
  margin-bottom: ${rhythm(1 / 4)};
`;
